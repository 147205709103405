
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Icon1 from "../../../images/VMU.svg"
import Icon2 from "../../../images/SIEMU.svg"
import Icon3 from "../../../images/ComplianceU.svg"
import Consult from '../consult/consult';
import Support from '../support/support';
import Managedservices from '../managedservices/managedservices';
import Partnerled from '../partnerled/partnerled';
import HeroSection from "../heroSection/index";
import ServiceoptionTrad from "../serviceoptionTrad/serviceoption";


import "./traditional.css"


const ServiceCard = styled.div`
  text-align: center;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  align-items: center;
  max-height: 400px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;

    &:hover {
        transform: scale(1.02);
    }
`;

const ServicesIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 40px;
    justify-content: flex-start;
    align-items: center;
`



const Traditional = () => {
    return (
        <>
            <HeroSection></HeroSection>
            <ServiceoptionTrad></ServiceoptionTrad>
            <Consult></Consult>
            <Support></Support>
            <Managedservices></Managedservices>
            <Partnerled></Partnerled>
        </>

    );
};




export default Traditional;




