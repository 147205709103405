// serviceoption.js

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ServiceOption = () => {
    const [activeButton, setActiveButton] = useState('NIST');

    const handleButtonClick = (button) => {
        setActiveButton(button);

        // Define the URLs for NIST and Traditional
        const nistUrl = '/services'; // Replace with your NIST URL
        const traditionalUrl = '/traditional'; // Replace with your Traditional URL

        // Open the corresponding URL based on the clicked button
        switch (button) {
            case 'NIST':
                window.location.href = nistUrl;
                break;
            case 'Traditional':
                window.location.href = traditionalUrl;
                break;
            default:
                break;
        }
    };

    return (
        <div className="container mt-5 text-center sticky-top p-3 " style={{ backgroundColor: '#000116' }} >
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className="card-text text-white text-bold text-center">Display Option:</h1>
                    <button
                        type="button"
                        className={`btn ${activeButton === 'NIST' ? 'btn-primary' : 'btn-secondary'} btn-lg btn-block mx-2`}
                        style={{
                            backgroundColor: activeButton === 'NIST' ? '#0ED0F3' : '',
                            color: activeButton === 'NIST' ? 'white' : '',
                        }}
                        onClick={() => handleButtonClick('NIST')}
                    >
                        NIST View
                    </button>

                    <button
                        type="button"
                        className={`btn ${activeButton === 'Traditional' ? 'btn-primary' : 'btn-secondary'} btn-lg btn-block mx-2`}
                        style={{
                            backgroundColor: activeButton === 'Traditional' ? '#0ED0F3' : '',
                            color: activeButton === 'Traditional' ? 'white' : '',
                        }}
                        onClick={() => handleButtonClick('Traditional')}
                    >
                        Traditional
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ServiceOption;
