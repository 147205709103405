import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Icon1 from "../../../images/VMU.svg"
import Icon2 from "../../../images/SIEMU.svg"
import Icon3 from "../../../images/ComplianceU.svg"

import "./managedservices.css"


const ServiceCard = styled.div`
  text-align: Center;
  background: transparent;
  color: #0DCAF0;
  display: flex;
  flex-direction: row;
  white-space: normal;
  border-radius: 5px;
  align-items: center;
  max-height: 50px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  margin-bottom: 10px;

    &:hover {
        transform: scale(1.10);
        background: white;
        color: black;
        cursor: pointer;

    }
`;

const ServiceCardTW = styled.div`
  text-align: Center;
  background: transparent;
  color: #0DCAF0;
  display: flex;
  flex-direction: row;
  white-space: normal;
  border-radius: 5px;
  align-items: center;
  max-height: 50px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  margin-bottom: 10px;

    &:hover {
        transform: scale(1.10);
        background: white;
        color: black;
        cursor: pointer;

    }
`;

const Checkmark = styled.span`
  color: white;
  margin-left: 10px;

  &:hover {
        color: black;
        margin-left: 10px;

    }
`;


const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
`;





const Managedservices = () => {
    const FWLink = 'https://services.ncfiguard.com/contact-form';
    const SMLink = 'https://services.ncfiguard.com/contact-form';
    const PCILink = 'https://services.ncfiguard.com/contact-form';
    const VMlink = 'https://services.ncfiguard.com/contact-form';
    const BusLink = 'https://services.ncfiguard.com/contact-form';
    const ARLink = 'https://services.ncfiguard.com/contact-form';
    const EULink = 'https://services.ncfiguard.com/contact-form';

    // Function to open the external link
    const openExternalLink = (link) => {
        window.open(link, '_blank');
    };




    return (

        <div className="container mt-5">
            <h1 className="text-blue font-weight-bold text-center">.</h1>
            <h1 className="text-info font-weight-bold text-center">Managed Services:</h1>
            <h9 className="text-blue"> .</h9>
            <p className="text-white">The first functioot relate to the identification of threats but rather understand the environment to which you are trying to protect. The below services are directly designed to support and deliver comprehensive visibility into your environment. </p>

            <div className="row">


                <div className="col-md-4">

                    <ServiceCardTW className="card" onClick={() => openExternalLink(FWLink)}>

                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Vulnerability Management  </h8>
                        </div>
                    </ServiceCardTW>

                    <ServiceCardTW className="card" onClick={() => openExternalLink(SMLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Patch Management </h8>
                        </div>
                    </ServiceCardTW>

                    
                </div>



                <div className="col-md-4">
                    <ServiceCardTW className="card" onClick={() => openExternalLink(VMlink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Managed Firewall</h8>
                        </div>
                    </ServiceCardTW>

                    <ServiceCardTW className="card" onClick={() => openExternalLink(BusLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓ </Checkmark>    O365 Management </h8>
                        </div>
                    </ServiceCardTW>

                </div>




                <div className="col-md-4">
                    <ServiceCardTW className="card" onClick={() => openExternalLink(ARLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark>    Backup and Recovery </h8>
                        </div>
                    </ServiceCardTW>

                    <ServiceCardTW className="card" onClick={() => openExternalLink(EULink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓ </Checkmark>    Automation</h8>
                        </div>
                    </ServiceCardTW>

                    
                </div>


                {/* Add more service cards as needed */}
            </div>
        </div>


        




    );
};




export default Managedservices;




