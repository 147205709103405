import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Icon1 from "../../../images/VMU.svg"
import Icon2 from "../../../images/SIEMU.svg"
import Icon3 from "../../../images/ComplianceU.svg"

import "./consult.css"


const ServiceCard = styled.div`
  text-align: Center;
  background: transparent;
  color: #0DCAF0;
  display: flex;
  flex-direction: row;
  white-space: normal;
  border-radius: 5px;
  align-items: center;
  max-height: 50px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  margin-bottom: 10px;

    &:hover {
        transform: scale(1.10);
        background: white;
        color: black;
        cursor: pointer;

    }
`;




const Checkmark = styled.span`
  color: white;
  margin-left: 10px;

  &:hover {
        color: black;
        margin-left: 10px;

    }
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
`;





const Consult = () => {
    const VMLink = 'https://services.ncfiguard.com/contact-form';
    const SIEMLink = 'https://services.ncfiguard.com/contact-form';
    const IRLink = 'https://services.ncfiguard.com/contact-form';
    const RiskALink = 'https://services.ncfiguard.com/contact-form';
    const AutoLink = 'https://services.ncfiguard.com/contact-form';
    const FWRulebaseLink = 'https://services.ncfiguard.com/contact-form';
    const SNDLink = 'https://services.ncfiguard.com/contact-form';
    const PhishingSLink = 'https://services.ncfiguard.com/contact-form';
    const BandRLink = 'https://services.ncfiguard.com/contact-form';
    const CMLink = 'https://services.ncfiguard.com/contact-form';
    const EDRLink = 'https://services.ncfiguard.com/contact-form';
    const SMLink = 'https://services.ncfiguard.com/contact-form';
    const PPDLink = 'https://services.ncfiguard.com/contact-form';
    const FWdULink = 'https://services.ncfiguard.com/contact-form';
    const EmailFLink = 'https://services.ncfiguard.com/contact-form';
    const PUAMLink = 'https://services.ncfiguard.com/contact-form';
    const PDLink = 'https://services.ncfiguard.com/contact-form';
    const MDLink = 'https://services.ncfiguard.com/contact-form';
    const AMLink = 'https://services.ncfiguard.com/contact-form';
    const SARLink = 'https://services.ncfiguard.com/contact-form';
    const SRMLink = 'https://services.ncfiguard.com/contact-form';
    const SCALink = 'https://services.ncfiguard.com/contact-form';
    const O365Link = 'https://services.ncfiguard.com/contact-form';
    const WebGLink = 'https://services.ncfiguard.com/contact-form';
    const AzureLink = 'https://services.ncfiguard.com/contact-form';
    const BusLink = 'https://services.ncfiguard.com/contact-form';
    const BidLink = 'https://services.ncfiguard.com/contact-form';
  

    // Function to open the external link
    const openExternalLink = (link) => {
        window.open(link, '_blank');
    };

    return (      
        <div className="container mt-5">
            <h1 className="text-info font-weight-bold text-center">Consulting Services:</h1>
            <h9 className="text-blue"> .</h9>
            <p className="text-white">The first functioot relate to the identification of threats but rather understand the environment to which you are trying to protect. The below services are directly designed to support and deliver comprehensive visibility into your environment. </p>

            <div className="row">

           
                <div className="col-md-4">

                    <ServiceCard className="card" onClick={() => openExternalLink(VMLink)}>
                        <div className="card-body" >
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Vulnerability Management</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(SIEMLink)}>
                        <div className="card-body" >
                            <h8 className="card-title"><Checkmark>✓</Checkmark> SIEM / Log Onboarding</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(IRLink)}>
                        <div className="card-body">
                            <h8 className="card-title "><Checkmark>✓</Checkmark> Incident Response Readiness </h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(RiskALink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Risk Assessment</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(AutoLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Automation (Python)</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(FWRulebaseLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Firewall Rulebase Review</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(SNDLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Secure Network Design</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(PhishingSLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Phishing Simulations</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(BandRLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Backup and Recovery</h8>
                        </div>
                    </ServiceCard>
                </div>









                <div className="col-md-4">
                    <ServiceCard className="card" onClick={() => openExternalLink(CMLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark>    Cloud Migration</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(EDRLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓ </Checkmark>    EDR/XDR Deployment</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(SMLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark>  Strategy and Maturity</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(PPDLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Process and Procedure Development </h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(FWdULink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Firewall Deployment/Upgrade</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(EmailFLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Email Filtering</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(PUAMLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> PUAM</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(PDLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Policy Development </h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(MDLink)}>
                        <div className="card-body">
                            <h8 className="card-title"> <Checkmark>✓</Checkmark> Mobile Device Management</h8>
                        </div>
                    </ServiceCard>
                </div>






                <div className="col-md-4">
                    <ServiceCard className="card" onClick={() => openExternalLink(AMLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark>    Asset Management</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(SARLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓ </Checkmark>    Secure Architecture Review</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(SRMLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark>  Server Refresh and Migration </h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(SCALink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Secure Configuration Assessment</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(O365Link)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Office365 Review & Deployment</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(WebGLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Web Gateway</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(AzureLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Azure Review & Configuration</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(BusLink)}>
                        <div className="card-body">
                            <h8 className="card-title"><Checkmark>✓</Checkmark> Business Reporting</h8>
                        </div>
                    </ServiceCard>

                    <ServiceCard className="card" onClick={() => openExternalLink(BidLink)}>
                        <div className="card-body">
                            <h8 className="card-title"> <Checkmark>✓</Checkmark> Bid Response</h8>
                        </div>
                    </ServiceCard>
                </div>


                {/* Add more service cards as needed */}
            </div>
        </div>




    );
};




export default Consult;




