import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { request } from 'graphql-request';
import './BlogPost.css'; // Import a CSS file for styling

const BlogPage = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const { posts } = await request(
          'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clnk6yx690mui01t4161jb63p/master',
          `
            query {
              posts {
                id
                title
                slug
                excert
                featuredImage {
                  url
                }
              }
            }
          `
        );

        setPosts(posts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchPosts();
  }, []);

  if (posts.length === 0) {
    return 'Loading...';
  }

  return (
  <div>
  <br/><br/><br/><br/>
    <h1 style={headerStyle}>N.C.F.I Blog Posts</h1>
    <br/><br/>
    <div className="blog-container">
      {posts.map(({ id, title, slug, excert, featuredImage }) => (
        <div key={id} className="blog-post">
          {featuredImage && featuredImage.url && (
            <img src={featuredImage.url} alt={title} className="blog-image" />
          )}
          <h2 className="blog-title">{title}</h2>
          <p className="blog-excerpt">{excert}</p>
          <Link to={`/blog/${slug}`} className="blog-link">
            Read more
          </Link>
        </div>
      ))}
    </div>
    </div>
  );
};
const headerStyle = {
  color: 'white',
  textAlign: 'center',
};
export default BlogPage;
