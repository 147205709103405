import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ServicesPage from "./pages/Services";
import HomePage from "./pages/Home";
import AboutPage from "./pages/About";
import BlogPage from "./pages/Blog"
import BlogPageSingle from "./pages/Blog/Page"
import SigninPage from "./pages/signin";
import ContactPage from "./pages/Contact";
import Layout from "./components/Layout";
import IdentityService from './pages/Services/Identity';
import protect from './pages/Services/protect/protect.js';
import detect from './pages/Services/detect/detect';
import respond from './pages/Services/respond/respond';
import recovery from './pages/Services/recovery/recovery';
import consult from './pages/Services/consult/consult';
import support from './pages/Services/support/support';
import managedservices from './pages/Services/managedservices/managedservices';
import traditional from './pages/Services/traditional/traditional';
import partnerled from './pages/Services/partnerled/partnerled';
import Vulnm from './pages/Services/allservices/VulnerabilityManagement/vulnm';

import ReactGA from 'react-ga';
const TRACKING_ID = "G-24M719M66T"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


function App() {
  return (
    <Layout>
    <Router>
      <Switch>
                    <Route path="/" component={HomePage} exact />
                    <Route path="/about" component={AboutPage} exact />
                    <Route path="/blog" component={BlogPage} exact />
        <Route path="/blog/:slug" component={BlogPageSingle} />

              <Route path="/services" component={ServicesPage} exact />
        <Route path="/signin" component={SigninPage} exact />
        <Route path="/contact" component={ContactPage} exact />
                  <Route path="/myservices" component={IdentityService} exact />
                  <Route path="/protect" component={protect} exact />
                  <Route path="/detect" component={detect} exact />
                  <Route path="/respond" component={respond} exact />
                  <Route path="/recovery" component={recovery} exact />
                  <Route path="/consult" component={consult} exact />
                  <Route path="/support" component={support} exact />
                  <Route path="/managedservices" component={managedservices} exact />
                  <Route path="/partnerled" component={partnerled} exact />
                  <Route path="/traditional" component={traditional} exact />
                  <Route path="/VulnerabilityManagement" component={Vulnm} exact />
               
      </Switch>
    </Router>
    </Layout>
  );
}

export default App;
