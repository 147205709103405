import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Icon1 from "../../../images/SOC.png"
import Icon2 from "../../../images/SANSR.png"
import Icon3 from "../../../images/IR.svg"
import Icon4 from "../../../images/auto.png"




import "./respond.css"


const ServiceCard = styled.div`
    text-align: left;
  background: white;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  //align-items: center;
  max-height: 400px;
  min-height: 250px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;

    &:hover {
        transform: scale(1.08);
    }
`;

const ServicesIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 40px;
    justify-content: flex-start;
    align-items: center;
`



const Respond = () => {
    return (




        <div className="container mt-5">
            <h1 className="text-info font-weight-bold text-center">Respond:</h1>
            <h9 className="text-blue"> .</h9>
            <p className="text-white">Being able to respond to a threat within your environment is a core function of any security programme to ensure threats are contained and eliminated before impacting business systems and functions. The below services are designed to ensure expert level response to any threat identified within your business. </p>

            <div className="row">

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon1} />
                        <div className="card-body">
                            <h5 className="card-title">Managed Detection and Response </h5>
                            <p className="card-text">Expert monitoring and rapid response to fortify any organisation from the latest threats  </p>
                            <a href="#" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon2} />
                        <div className="card-body">
                            <h5 className="card-title">Response Readiness</h5>
                            <p className="card-text">Have your incident response plans developed, reviewed and tested by industry experts</p>
                            <a href="#" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>



                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon3} />
                        <div className="card-body">
                            <h5 className="card-title">Incident Response </h5>
                            <p className="card-text">Gain peace of mind with dedicated incident response experts on standby, ready to swiftly and effectively manage and mitigate cybersecurity incidents</p>
                            <a href="#" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>
                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon4} />
                        <div className="card-body">
                            <h5 className="card-title">Automation </h5>
                            <p className="card-text">Leverage automation to accelerate response activities, reduce 'time-to-remediate,' and enhance operational efficiency.</p>
                            <a href="#" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>





                {/* Add more service cards as needed */}
            </div>
        </div>




    );
};




export default Respond;




