import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Icon1 from "../../../images/VMU.svg"
import Icon2 from "../../../images/CSU.svg"
import Icon3 from "../../../images/ComplianceU.svg"
import Icon4 from "../../../images/DomainMonitor.png"

import "./myservicescss.css"


const ServiceCard = styled.div`
 text-align: left;
  background: white;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  //align-items: center;
  max-height: 400px;
  min-height: 250px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;

    &:hover {
        transform: scale(1.08);
    }
`;

const ServicesIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 40px;
    justify-content: flex-start;
    align-items: center;
`



const IdentityService = () => {
    return (




        <div className="container mt-5">
            <h1 className="text-info font-weight-bold text-center">IDENTIFY: </h1>
            
            <h5 className="text-white font-weight-bold text-center ">"If You Don't Know About It, You Can Not Protect It !"</h5>
            <h9 className="text-blue"> .</h9>

            <p className="text-white">The first function of any security program is to identify which does not relate to the identification of threats but rather the understand of an environment to which you are trying to protect. The below services are directly designed to support and deliver comprehensive visibility into your environment and maturity. </p>

            <div className="row">

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon1} />
                        <div className="card-body">
                            <h5 className="card-title">Asset Management:</h5>
                            <p className="card-text">Identify all assets within your environment for complete visibility and build support frameworks to deliver true asset management.</p>
                            <a href="https://services.ncfiguard.com/contact-form" class="btn btn-primary">Find Out More</a>
                        </div>
                        
                    </ServiceCard>
                </div>

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon2} />
                        <div className="card-body">
                            <h5 className="card-title">Security Assessment:</h5>
                            <p className="card-text">Benchmark your security against industry standards such as NISTm ISO and NIS2 to identify gaps and kickstart security programs.</p>
                            <a href="https://services.ncfiguard.com/contact-form" class="btn btn-primary">Find Out More</a>
                        </div>
                        
                    </ServiceCard>
                </div>

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon3} />
                        <div className="card-body">
                            <h5 className="card-title">Governance:</h5>
                            <p className="card-text">Tailored service to meet the needs of your business for the audit, review and creation of policies, procedures and frameworks across all business areas. </p>
                            <a href="https://services.ncfiguard.com/contact-form" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon4} />
                        <div className="card-body">
                            <h5 className="card-title">Domain Monitoring:</h5>
                            <p className="card-text">Your external perimeter is not just your firewall. Protect your domains now with visability and monitoring of attacks from external threats</p>
                            <a href="https://services.ncfiguard.com/contact-form" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>

            </div>
        </div>




    );
};




export default IdentityService;




