
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Icon3 from "../../../../images/NAC.png"
import Icon4 from "../../../../images/EDR.png"
import Icon5 from "../../../../images/perimiter.jpg"
import Icon6 from "../../../../images/EndUserTrainimg.webp"
import Icon7 from "../../../../images/ESU.svg"
import Icon8 from "../../../../images/VMS.png"
import HeroSection from "../../heroSection/index";

import "./vulnm.css"


const ServiceCard = styled.div`
  text-align: left;
  background: transparent;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  //align-items: center;
  max-height: 400px;
  min-height: 550px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;
  margin-top: 100px;
  color: white;

    &:hover {
        transform: scale(1.01);
    }
`;

const ServicesIcon = styled.img`
    height: 400px;
    width: 400px;
    margin-bottom: 10px;
    justify-content: flex-start;
    margin-top: 100px;
    align-items: center;
`


const PageContainer = styled.div`
  height: 50px;
  margin: 0 auto;
  padding: 40px;
  display: column;
`;

const Title = styled.h1`
  color: white; // Customize the color if needed
  font-weight: bold;

`;

const Paragraph = styled.p`
  color: white; // Customize the color if needed
`;

const Image = styled.img`
  width: 100%; // Make sure the image takes the full width of its container
  max-width: 400px; // Adjust the maximum width if needed
  height: auto;
`;




const Vulnm = () => {

    return (
        <div className="container mt-5">
            
            
            

            <div className="row">

                <div className="col-md-6">
                    <ServiceCard className="card">
                        
                        <div className="card-body">
                            <h5 className="card-title">Vulnerability Management</h5>
                            <p className="card-text">

                                Identify, Contextualise and Remediate Vulnerabilities Within 
                                Contextualise and Remediate Vulnerabilities 
                                Contextualise and Remediate Vulnerabilities Within Identify, 
                                Contextualise and Remediate Vulnerabilities Within Identify, 
                                Contextualise and Remediate Vulnerabilities Within Identify, 
                                Contextualise and Remediate Vulnerabilities Within Identify, 
                                Contextualise and Remediate Vulnerabilities Within Identify, 
                                Contextualise and Remediate Vulnerabilities Within Identify, 
                                Contextualise and Remediate Vulnerabilities Within Identify, 
                                Contextualise and Remediate Vulnerabilities Within Identify, 
                                Contextualise and Remediate Vulnerabilities Within Identify, 
                                Contextualise and Remediate Vulnerabilities Within Identify, 





                            </p>
                            <a href="https://services.ncfiguard.com/contact-form" class="btn btn-primary">Find Out More</a>
                        </div>
                        
                    </ServiceCard>
                </div>


                <div className="col-md-4">
                    <ServicesIcon src={Icon8} />
                </div>


                

              






                {/* Add more service cards as needed */}
            </div>
        </div>
    );
};




export default Vulnm;




