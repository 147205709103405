// BlogPost.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { request } from 'graphql-request';

const BlogPost = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const { posts } = await request(
          'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clnk6yx690mui01t4161jb63p/master',
          `
            query {
              posts {
                id
                title
                slug
                excert
                featuredImage {
                  url
                }
              }
            }
          `
        );

        setPosts(posts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchPosts();
  }, []);

  if (posts.length === 0) {
    return 'Loading...';
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Set slidesToShow dynamically
    slidesToScroll: 1,
  };

  return (
<div style={containerStyle}>
  <h1 style={headerStyle}>Recent Blog Posts</h1>
  <br/><br/>
  <Slider {...settings}>
    {posts.map(({ id, title, slug, excert, featuredImage }) => (
      <div key={id} style={slideStyle}>
        {featuredImage && featuredImage.url && (
          <img src={featuredImage.url} alt={title} style={imageStyle} />
        )}
        <h2 style={titleStyle}>{title}</h2>
        <p style={excertStyle}>{excert}</p>
        <Link to={`/blog/${slug}`} style={linkStyle}>
          Read more
        </Link>
      </div>
    ))}
  </Slider>
</div>

  );
};

// Styles
const containerStyle = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
};

const slideStyle = {
  textAlign: 'center',
  padding: '20px',
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const imageStyle = {
  maxWidth: '100%',
  height: 'auto',
  borderRadius: '8px',
  marginBottom: '10px',
};

const titleStyle = {
  fontSize: '1.5rem',
  marginBottom: '10px',
  color: '#FFFFFF',

};

const excertStyle = {
  color: '#555',
};

const headerStyle = {
  color: 'white',
  textAlign: 'center',
};



const linkStyle = {
  color: '#007BFF',
  textDecoration: 'none',
  display: 'block',
  marginTop: '10px',
};

export default BlogPost;
