import React, { useState, useEffect } from "react";

import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import Dropdown from "react-dropdown"; // Import the Dropdown component
import "react-dropdown/style.css"; // Import the Dropdown styles
import "./napps.css";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavBlogsLink,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
import "./../../App.css";




export const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Define the dropdown state
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);      
  }, []);


  ////////////////////// CHAT GPT 
  const insightsDropdownOptions = [
    { value: "Option 1", label: "Consultancy" },
    { value: "Option 2", label: "Vulnerability Management" },
    { value: "Option 3", label: "Governance" },
  ];

  const handleInsightsDropdown = (option) => {
    // Implement logic to navigate to the selected option
  };
  /////////////////////////////////////////////////////////////////


  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
                  <NavLogo as="a" href="/" rel="noopener noreferrer">
                      <h1 className="logo-text">N.C.F.I</h1>
                  </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>

<NavItem>
  <NavBlogsLink
    as="a" // Use the <a> element
                              href="/" // Replace with your external link
    rel="noopener noreferrer" // Recommended for security and performance
  >
    Home
  </NavBlogsLink>

</NavItem>



<NavItem>
  <NavBlogsLink
    as="a" // Use the <a> element
    href="/services" // Replace with your external link
    rel="noopener noreferrer" // Recommended for security and performance
  >
    Services
  </NavBlogsLink>
</NavItem>




                      <NavItem>
                          <NavBlogsLink
                              as="a" // Use the <a> element
                              href="/about" // Replace with your external link
                              rel="noopener noreferrer" // Recommended for security and performance
                          >
                              About NCFI
                          </NavBlogsLink>
                      </NavItem>


                      <NavItem>
                          <NavBlogsLink
                              as="a" // Use the <a> element
                              href="/blog" // Replace with your external link
                              rel="noopener noreferrer" // Recommended for security and performance
                          >
                              Blog
                          </NavBlogsLink>
                      </NavItem>





            
                  </NavMenu>


          <NavBtn>
                      <NavBtnLink
                          as="a" // Use the <a> element
                          href="/contact" // Replace with your external link
                          rel="noopener noreferrer" // Recommended for security and performance
                      >
                          Contact Us

                          </NavBtnLink>
                  </NavBtn>




        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
