import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from 'react-router-dom';
import "./../../../App.css";
import { InfoContainer, InfoWrapper, Subtitle, Title } from "./InfoElements";
import { ButtonBasic } from "../ButtonElements";

document.body.style.backgroundColor = "#000116";
export const InfoSection = ({ id, title, subtitle, text, image, btnText }) => {
const history = useHistory();
  const handleButtonClick = () => {
    // Navigate to /contact when the button is clicked
    history.push('/contact');
  };
  return (
    <>
      <InfoContainer id={id} className="bg-black">
        <InfoWrapper>
          <div className="row expand-row gx-5">
            <div className="col-lg-6 col-sm-12 my-auto">
              <Subtitle>{subtitle}</Subtitle>
              <Title className="text-info mb-6">{title}</Title>
              <p className="text-white mb-4">{text}</p>
              <ButtonBasic  onClick={handleButtonClick} primary="true" dark="true" className="mx-auto">
                {btnText}
              </ButtonBasic>
            </div>
            <div className="col-lg-6 col-sm-12 img-wrap">
              <img src={image} alt="" className="fit-img"></img>
            </div>
          </div>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
