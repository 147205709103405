import React, { useState } from "react";
import {
  FooterContainer,
  FooterH2,
  FooterLink,
  FooterWrapper,
} from "./FooterElements";
import "../../App.css";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  const handleSubscribe = async () => {
    try {
      const response = await fetch('https://44p23n309e.execute-api.eu-west-1.amazonaws.com/PROD/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        // Handle successful subscription
        console.log('Subscription successful');
        setSubscriptionStatus('Success');
      } else {
        // Handle subscription error
        console.error('Error subscribing:', response.statusText);
        setSubscriptionStatus('Error');
      }
    } catch (error) {
      console.error('Error subscribing:', error);
      setSubscriptionStatus('Error');
    }
  };

  return (
    <FooterContainer>
      <FooterWrapper className="container py-5">
        <div className="row gx-5 px-4 gy-4">
          <div className="col-lg-3 col-sm-6">
            <FooterH2>About</FooterH2>
            <FooterLink href="/about">About Us</FooterLink>
            <FooterLink href="#">Clients</FooterLink>
            <FooterLink href="#">Testimonials</FooterLink>
            <FooterLink href="#">Cookies</FooterLink>
          </div>
          <div className="col-lg-3 col-sm-6">
            <FooterH2>Contact Us</FooterH2>
            <FooterLink href="/contact">Contact</FooterLink>
            <FooterLink href="#">Support</FooterLink>
          </div>
        <div className="col-lg-3 col-sm-6">
          <FooterH2>Newsletter Signup</FooterH2>
          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {subscriptionStatus === 'Success' ? (
            <span className="text-success">Success</span>
          ) : (
            <button className="btn btn-primary" onClick={handleSubscribe}>
              Subscribe
            </button>
          )}
        </div>
          <div className="col-lg-3 col-sm-6">
            <FooterH2>Follow Us</FooterH2>
            <FooterLink href="https://www.facebook.com/ncfi.fb" target="_blank">Facebook</FooterLink>
            <FooterLink href="https://www.linkedin.com/company/92817447" target="_blank">LinkedIn</FooterLink>
          </div>
        </div>

      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
