import React from 'react'
import {SidebarContainer, Icon, CloseIcon, SidebarRoute, SideBtnWrap, SidebarWrapper, SidebarMenu, SidebarLink} from './SidebarElements'

export const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>

                    <SidebarLink as="a" href="https://www.ncfiguard.comt" onClick={toggle}>
                        Home
                    </SidebarLink>

                    <SidebarLink as="a" href="https://www.ncfiguard.com/About" onClick={toggle}>
                        About
                    </SidebarLink>


                    <SidebarLink as="a" href="https://services.ncfiguard.com/" onClick={toggle}>
                        Services
                    </SidebarLink>



                    <SidebarLink as="a" href="https://www.ncfiguard.com/contact-form" onClick={toggle}>
                        Contact Us
                    </SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to="/signin" onClick={toggle}>
                        Sign In
                    </SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}