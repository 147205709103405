import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Icon1 from "../../../images/SIEMU.svg"
import Icon2 from "../../../images/ID2.png"
import Icon3 from "../../../images/webapppng.png"
import Icon4 from "../../../images/TI.png"

import "./detect.css"


const ServiceCard = styled.div`
   text-align: left;
  background: white;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  //align-items: center;
  max-height: 400px;
  min-height: 250px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;

    &:hover {
        transform: scale(1.08);
    }
`;

const ServicesIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 40px;
    justify-content: flex-start;
    align-items: center;
`



const Detect = () => {
    return (




        <div className="container mt-5">
            <h1 className="text-info font-weight-bold text-center">Detect:</h1>
            <h9 className="text-blue"> .</h9>
            <p className="text-white">Although there is no limited to the amount of technologies and investment that can be made in protecting an organisation, it is important to understand that protective controls may not meet the challenges of advanced threats so it is crucial to implement detective controls to detect malicious activity within the organisation should it occur.  </p>

            <div className="row">

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon1} />
                        <div className="card-body">
                            <h5 className="card-title">SIEM</h5>
                            <p className="card-text">Audit Logging and monitoring of all events within your environment for complete visibility</p>
                            <a href="#" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon2} />
                        <div className="card-body">
                            <h5 className="card-title">Intrusion Detection</h5>
                            <p className="card-text">Complete visibility of network activity to detect malicious behaviour within your environment</p>
                            <a href="#" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>





                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon3} />
                        <div className="card-body">
                            <h5 className="card-title">Web Application Monitoring  </h5>
                            <p className="card-text">Ensure complete monitoring of internal and external applications to detect malicious changes </p>
                            <a href="#" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon4} />
                        <div className="card-body">
                            <h5 className="card-title">Threat Intelligence</h5>
                            <p className="card-text">Enhance the ability to detect malicious activity with threat intelligence feeds  </p>
                            <a href="#" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>





                {/* Add more service cards as needed */}
            </div>
        </div>




    );
};




export default Detect;




