import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { request } from 'graphql-request';
import './BlogPost.css'; // Import a CSS file for styling

const BlogPageSingle = () => {
  const [post, setPost] = useState(null);
  const location = useLocation();
  const blogname = location.pathname.split('/blog/')[1];

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const { posts } = await request(
          'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clnk6yx690mui01t4161jb63p/master',
          `
            query {
              posts(where: { slug: "${blogname}" }) {
                id
                title
                slug
                excert
                featuredImage {
                  url
                }
                featuredPost
                author { name }
                categories { name }
                content { html }
              }
            }
          `
        );

        if (posts && posts.length > 0) {
          setPost(posts[0]);
        }
      } catch (error) {
        console.error('Error fetching blog post:', error);
      }
    };

    fetchPost();
  }, [blogname]);

  if (!post) {
    return 'Loading...';
  }

  const { title, excert, featuredImage, author, categories, content } = post;

  return (
    <div>
      <br/><br/><br/><br/>
      <h1 style={headerStyle}>{title}</h1>
      <div className="blog-single-container">
        {featuredImage && featuredImage.url && (
          <img src={featuredImage.url} alt={title} className="blog-single-image" />
        )}
        <div className="blog-single-meta">
          <span className="meta-item">Author: {author.name}</span><br/>
          <span className="meta-item">Categories: {categories.map(category => category.name).join(', ')}</span><br/><br/>
        </div>
        <div className="blog-single-content" dangerouslySetInnerHTML={{ __html: content.html }} />
        <Link to={`/blog/`} className="blog-link">
          Back to All Posts
        </Link>
      </div>
    </div>
  );
};

const headerStyle = {
  color: 'white',
  textAlign: 'center',
};

export default BlogPageSingle;
