import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Icon1 from "../../../images/CloudM.png"

import "./recovery.css"


const ServiceCard = styled.div`
    text-align: left;
  background: white;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  //align-items: center;
  max-height: 400px;
  min-height: 250px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;

    &:hover {
        transform: scale(1.08);
    }
`;

const ServicesIcon = styled.img`
    height: 180px;
    width: 180px;
    margin-bottom: 20px;
    justify-content: flex-start;
    align-items: center;
`



const Recovery = () => {
    return (




        <div className="container mt-5">
            <h1 className="text-info font-weight-bold text-center">Recover:</h1>
            <h9 className="text-blue"> .</h9>
            <p className="text-white">Ensure peace of mind by guaranteeing the efficient restoration of all business functions, regardless of the incident. Utilize secure immutable backups that can be accessed within minutes, enabling your business to resume operations within hours rather than days. </p>

            <div className="row">

                <div className="col-md-12">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon1} />
                        <div className="card-body">
                            <h5 className="card-title">Secure backup and Recovery</h5>
                            <p className="card-text">Safeguard your critical data by leveraging cloud-based storage and immutable backups. Ensuring piece of mind that all backups can be recovered when needed</p>
                            <a href="#" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>

                

             




                {/* Add more service cards as needed */}
            </div>
        </div>




    );
};




export default Recovery;




