// index.js

import React, { useState, useRef} from "react";
import { Navbar } from "./../../components/navbar";
import { Sidebar } from "./sidebar";
import HeroSection from "./heroSection/index";
import ServiceOption from "./serviceoption/serviceoption"; // Moved ServiceOption here
import IdentityService from "./Identity";
import Protect from "./protect/protect";
import Detect from "./detect/detect";
import Respond from "./respond/respond";
import Recovery from "./recovery/recovery";
import styled from "styled-components";
import { Link } from "react-router-dom";



export const ServicesPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const identity = useRef(null)
    const respond = useRef(null)

const GuideBarContainer = styled.div`
  position: fixed;
  top: 50%; // Adjust to your preferred vertical position
  left: 5;
  transform: translateY(-50%);
  background-color: black;
  color: #0ED0F3;
  padding: 10px;
  z-index: 1000; // Make sure it's above other elements
  overflow: hidden;
  cursor: pointer;

  &:hover {
    width: 120px; // Adjust the width based on your content
    padding: 50px;

  }
`;

const ExpandedContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  width: 0;
  transition: width 0.3s ease; // Add a transition for smooth animation

    a {
    text-decoration: none;
    color: ${props => (props.expanded ? "#0ED0F3" : "#0ED0F3")};
    cursor: pointer;
  }
`;


const GuideBar = () => {
    const [expanded, setExpanded] = React.useState(false);

    const scrollToSection = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({
            behavior: "smooth",
        });
    };


    return (
        <GuideBarContainer
            onMouseEnter={() => setExpanded(true)}
            onMouseLeave={() => setExpanded(false)}
        >
            <ExpandedContent expanded={expanded}>
                <a href="#identify">
                    <div>{expanded ? "Identify" : "I"}</div>
                </a>
                <p className="card-text"> </p>
                <a href="#protect">
                    <div>{expanded ? "Protect" : "P"}</div>
                </a>
                <p className="card-text"> </p>
                <a href="#detect">
                    <div>{expanded ? "Detect" : "D"}</div>
                </a>
                <p className="card-text"> </p>
                <a href="#respond">
                    <div>{expanded ? "Respond" : "R"}</div>
                </a>
                <p className="card-text"> </p>
                <a href="#recover">
                    <div>{expanded ? "Recover" : "R"}</div>
                </a>

            </ExpandedContent>
        </GuideBarContainer>
    );
};

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Navbar toggle={toggle} />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <HeroSection />
            <ServiceOption />
            <section id='identify'>
            <IdentityService />
            </section>
            <GuideBar />
            <section id='protect'>
            <Protect />
            </section>
            <section id='detect'>
            <Detect />
            </section>
            <section id='respond'>
            <Respond />
            </section>
            <section id='recover'>
            <Recovery />
            </section>


        </>
    );
};
export default ServicesPage;