
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Icon1 from "../../../images/VMU.svg"
import Icon2 from "../../../images/SIEMU.svg"
import Icon3 from "../../../images/NAC.png"
import Icon4 from "../../../images/EDR.png"
import Icon5 from "../../../images/perimiter.jpg"
import Icon6 from "../../../images/EndUserAwareness.png"
import Icon7 from "../../../images/ESU.svg"
import Icon8 from "../../../images/VMS.png"


import "./protect.css"


const ServiceCard = styled.div`
     text-align: left;
  background: white;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  //align-items: center;
  max-height: 400px;
  min-height: 250px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;

    &:hover {
        transform: scale(1.08);
    }
`;

const ServicesIcon = styled.img`
    height: 160px;
    width: 180px;
    margin-bottom: 10px;
    justify-content: flex-start;
    align-items: center;
`



const Protect = () => {
    return (

        <div className="container mt-5">
            <h1 className="text-blue font-weight-bold text-center">.</h1>
            <h1 className="text-info font-weight-bold text-center">PROTECT:</h1>
            <h9 className="text-blue"> .</h9>
            <p className="text-white">Once an organisation understands its maturity and the assets within the environment, intelligent investment can be made to ensure that those assets and information is protected through layers of defence. The below services are designed to provide maximum protection of your environment. </p>

            <div className="row">

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon8} />
                        <div className="card-body">
                            <h5 className="card-title">Vulnerability Management</h5>
                            <p className="card-text">Identify, Contextualise and Remediate Vulnerabilities Within Your Environment to Protect From The Latest Threats</p>
                            <a href="https://services.ncfiguard.com/contact-form" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon4} />
                        <div className="card-body">
                            <h5 className="card-title">EDR/XDR</h5>
                            <p className="card-text">Defend Against The Most Sophisticated Malware Threats With The Most Advanced Solutions to provide complete protection</p>
                            <a href="https://services.ncfiguard.com/contact-form" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon5} />
                        <div className="card-body">
                            <h5 className="card-title">Perimeter Defence</h5>
                            <p className="card-text">Implement The Latest Technology To Protect From The Latest external Threats To Your Organization </p>
                            <a href="https://services.ncfiguard.com/contact-form" class="btn btn-primary">Find Out More</a>
                        </div>

                    </ServiceCard>
                </div>

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon6} />
                        <div className="card-body">
                            <h5 className="card-title">End User Awareness </h5>
                            <p className="card-text">Protect Your Organisation By Ensuring Employees Are Trained Identify Threats Such as Phishing</p>
                            <a href="https://services.ncfiguard.com/contact-form" class="btn btn-primary">Find Out More</a>
                        </div>
                    </ServiceCard>
                </div>

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon3} />
                        <div className="card-body">
                            <h5 className="card-title">Network Access Control</h5>
                            <p className="card-text">Protect The Network and Critical Assets By Ensuring Only Secure and Authorised Devices Can Access The Network  </p>
                            <a href="https://services.ncfiguard.com/contact-form" class="btn btn-primary">Find Out More</a>
                        </div>
                    </ServiceCard>
                </div>

                <div className="col-md-6">
                    <ServiceCard className="card">
                        <ServicesIcon src={Icon7} />
                        <div className="card-body">
                            <h5 className="card-title">Email and Office365</h5>
                            <p className="card-text">Secure Your Business Email and Users while Enabling Growth and Expansion with Office365 </p>
                            <a href="https://services.ncfiguard.com/contact-form" class="btn btn-primary">Find Out More</a>
                        </div>
                    </ServiceCard>
                </div>






                {/* Add more service cards as needed */}
            </div>
        </div>




    );
};




export default Protect;




